#WOL {
    position: relative;
    margin: 0;
    padding: 0;
    width: 50%;
    flex-grow: 1;
    background-color: rgba(10, 10, 10, .8);
    border-radius: 0 .5rem .5rem 0 ;
    overflow: hidden;
    box-shadow: .2rem .2rem .3rem rgba(0, 0, 0, .3);
}

.noUsers {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
}

.noUsers h1 {
    margin: 0;
    padding: .5;
    color: white;
    font-size: 1.6rem;
    margin-left: 1rem;
}


.title {
    font-size: 2rem;
    font-weight: 500;
    margin: 0;
    padding: 1rem 2rem;
    background-color: var(--primary);
    color: var(--secondary);
    border-radius: 0 .5rem 0 0;
}