#wrapper {
    margin: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--primary);
    font-size: 2rem;
    background-color: white;
    border-radius: .5rem;
}

.number {
    margin: 0 1rem;
    font-size: 2rem;
}