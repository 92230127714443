.event-list-item {
    margin: .5rem;
    padding: 1rem 3rem;
    border-radius: .5rem;
    color: white;
    background-color: var(--primary);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 15rem;
    transition: .3s;
}

.event-list-item:hover {
    cursor: pointer;
    background-color: var(--secondary);
}

.event-list-item-h1 {
    margin: 0;
    font-size: 2rem;

}

.event-list-item-h2{
    margin: 0;
    font-size: 1.2rem;
}