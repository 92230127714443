.auth_bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: linear-gradient(
    to right top,
    #7b1fa2,
    #a2189a,
    #c01b8f,
    #d92b85,
    #ec407a
  );
}

.auth_mesh {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../mesh.svg');
    background-repeat: repeat;
    background-size: 40rem;
  }

.auth-wrapper {
  width: 90%;
  max-width: 500px;
  padding-top: 10%;
}
.login-card,
.forgot-card {
  background-color: transparent;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.auth-branding-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 2;
}
.auth-logo-wrapper {
  height: 14rem;
  margin: 3rem;
  margin-right: 0;
}
.auth-branding-titles {
  display: flex;
  flex-grow: 2;
  margin-top: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.auth-logo {
  width: 100%;
  max-width: 120px;
  margin-bottom: 4rem;
}

.login-card h1 {
  padding-top: 3rem;
  font-weight: 300;
  text-align: left;
  margin-bottom: 0;
  margin-left: 10%;
  margin-top: -5rem;
  color: white;
}
.login-card p {
  margin-left: 10%;
  margin-top: 0;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  color: white;
}

.login-form {
  display: flex;
  flex-wrap: wrap;
}

.auth-input {
  background-color: transparent;
  outline: none;
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid white;
  color: black;
}

.auth-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.6rem;
}

.login-btn {
  font-size: 2rem;
  font-weight: 400;
  width: 80%;
}

.enrol-btn {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
  width: 30%;
}

.enrol-cta-container {
  margin: 7px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enrol-cta-container p {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}

.forgot-card {
  display: flex;
  flex-direction: column;
}

.reset-sent-notice {
  color: white;
  margin: 0;
  padding: 10px 20px;
}

.spinner-center {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.auth-spinner {
  margin-top: 5rem;
}

.auth-input:-webkit-autofill,
.auth-input:-webkit-autofill:hover,
.auth-input:-webkit-autofill:focus,
.auth-input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
}
