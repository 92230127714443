.button {
    margin:1rem .5rem;
    padding: 1rem 3rem;
    border-radius: 5px;
    border-width: 1px;
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
    font-weight: 200;
    font-size: 1.3rem;
    cursor: pointer;
    position: relative;
    transition: all .2s;
}

.button span{
    position: relative;
    z-index: 2;
  }

.button:after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: var(--primary-500);
    transition: all .2s;
    border-radius: 5px;
    opacity: 0;
  }

.button:hover:after {
    height: 100%;
    opacity: 1;
  }

.button:focus, .button:active {
    border: 1px solid white;
    outline: none;
}

.button40 {
    width: 40%;
}

.button50 {
    width: 50%;
}

.button-100 {
    width: 100%;
}

.less-margin {
    margin: .3rem;
}

.buttonPrimary {
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
}
.buttonPrimary:after{
    background-color: var(--primary-500);
}


.secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}
.secondary:after{
    background-color: var(--secondary-500);
}

.danger {
    background-color: #B70F0F;
    border: 1px solid #D53D3D;
    color: white;
}
.danger:after{
    background-color: #770808;
}

.green {
    background-color: #168612;
    border: 1px solid #10640d;
    color: white;
}
.green:after {
    background-color: #10640d;
   color: white;
}


.warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #7B6715;
}
.warning:after{
    background-color: #e0a800;
}

.primaryOutline {
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.primaryOutline:after{
    background-color: white;
}

.dangerOutline{
    background-color: transparent;
    border: 1px solid #B70F0F;
    color: #B70F0F;
}
.dangerOutline:after{
    background-color: #B70F0F;
}

.dangerOutline:hover {
    color: white;
}

.isDisabled {
    background-color: gray;
    color: darkgray;
    border: 1px solid darkgray;
    width: 80%;
    cursor:  default;
}

.isDisabled:after {
    background-color: gray;
    color: darkgray;
    border: 0px solid darkgray;
    width: 80%;
}

.modalBtn {
    margin: 0;
    padding: 2rem;
    background-color: rgba(0, 0, 0, .3);
    margin-top: 2rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    font-size: 1.8rem;
}

.primaryLarge {
    font-size: 2rem;
    font-weight: 400;
    width: 80%;
}

.secondaryLarge {
    font-size: 1.4rem;
    font-weight: 400;
    width: 80%;
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}

.secondaryLarge:hover {
    background-color: var(--secondary-500);
    border: 1px solid var(--secondary-500);
    
}