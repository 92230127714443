.sqaureCard {
    position: relative;
    margin: 2rem 1rem;
    padding: 0;
    background-color: white;
    box-shadow: 0 .2rem .3rem rgba(0, 0, 0, .0);
    border-radius: .5rem;
    color: rgb(80, 80, 80);
    display: flex;
    width: 23rem;
    justify-content: flex-start;
    align-items: center;
}

.iconWrapper {
    padding: 2rem;
    padding-top: 0;
    font-size: 3rem;
    color: var(--primary);
    display: flex;
    align-items: flex-start;
    opacity: .8;
}

.contentWrapper {
    position: relative;
    padding: 1rem 0;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 1.4rem;
    text-align: center;
}

.value {
    text-align: center;
    font-size: 4rem;
    margin: 0;
    padding: p;
}

.dotsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.dotsWrapper span {
    margin-top: -2rem;
    margin-bottom: 1rem;
}

.dots div {
    background-color: var(--primary);
}