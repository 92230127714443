.input-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.form-field {
    display: block;
    width: 100%;
    padding: .8rem 1.6rem;
    line-height: 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border .3s ease; 
}

.no-group {
    border-radius: 6px!important;
}

.form-field::placeholder {
    color: var(--input-placeholder);
}

.form-field:focus {
    outline: none;
    border-color: var(--input-border-focus);
}

.form-group {
    margin: 0;
    position: relative;
    display: flex;
    width: 100%;
}

.form-padding {
    margin: 0 10%;
}

.form-group span, .form-group .form-field {
    white-space: nowrap;
    display: block;
    border-radius: 6px;
}

.form-group .form-field:not(:first-child):not(:last-child),
.form-group span:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.form-group .form-field:first-child,
.form-group span:first-child {
    border-radius: 6px 0 0 6px;
}

.form-group .form-field:last-child,
.form-group span:last-child{
    border-radius: 0 6px 6px 0;
}

.form-group .form-field:not(:first-child),
.form-group .span:not(:first-child) {
    margin-left: -.1rem;
}


.form-group .form-field {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
}

.form-group span {
    text-align: center;
    padding: .8rem 1.2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background .3s ease, border .3s ease, color .3s ease;
}

.form-group:focus-within span {
        color: var(--group-color-focus);
        background: var(--group-background-focus);
        border-color: var(--group-border-focus);
    }

   

   .inputError {
       
        color: darkred;
        font-weight: 600;
    
}